import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const config = {
    apiKey: "AIzaSyAzS2kSYAgIiWf7XwKepNspphUzWV1KXaA",
    authDomain: "remarkable-ninja.firebaseapp.com",
    databaseURL: "https://remarkable-ninja-default-rtdb.firebaseio.com",
    projectId: "remarkable-ninja",
    storageBucket: "remarkable-ninja.appspot.com",
    messagingSenderId: "823985089014",
    appId: "1:823985089014:web:51898660eb04b64f0ebfd7"
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const deliveryLogTable = firestore.collection("DeliveryLog");
export const functions = firebase.functions();
export const storage = firebase.storage();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({prompt: 'select_account'});
export const signInWithGoogle = () => auth.signInWithRedirect(provider);
export const signOut = () => auth.signOut();

export const authDevice = firebase.functions().httpsCallable('authDevice');

export default firebase;