import './MainPanel.css'
import Connected from "../Connected/Connected";
import Deliveries from "../Deliveries/Deliveries";
import {Grid} from "@material-ui/core";
import AdminPanel from "../AdminPanel/AdminPanel";
import StaticTimePicker from "../StaticTimePicker";
import {makeStyles} from '@material-ui/core/styles';
import StaticFileDelivery from "../StaticFileDelivery/StaticFileDelivery";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function MainPanel({profile}) {
    const classes = useStyles();
    return (
        <Grid container spacing={3} m={2}>
            <Grid item xs={12} sm={6}>
                <Connected profile={profile}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <StaticTimePicker profile={profile}/>
            </Grid>
            <Grid item xs={12}>
                <Deliveries profile={profile}/>
            </Grid>
            <Grid item xs={12}>
                <StaticFileDelivery profile={profile}/>
            </Grid>
            <Grid item xs={12}>
                <AdminPanel profile={profile}/>
            </Grid>
            {/*<Grid item xs={6} sm={3}>*/}
            {/*    <Paper className={classes.paper}>xs=6 sm=3</Paper>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3}>*/}
            {/*    <Paper className={classes.paper}>xs=6 sm=3</Paper>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3}>*/}
            {/*    <Paper className={classes.paper}>xs=6 sm=3</Paper>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3}>*/}
            {/*    <Paper className={classes.paper}>xs=6 sm=3</Paper>*/}
            {/*</Grid>*/}
        </Grid>
    )
}