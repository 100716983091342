import './StaticFileDelivery.css'
import firebase from "firebase";
import {
    Avatar, Button, Card, CardActions,
    CardContent, CardHeader, Grid,
    Slide
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../styles";
import useTemplates from "../../hooks/useTemplates";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {deliveryLogTable} from "../../firebase.utils";
import {useSnackbar, withSnackbar} from "notistack";
import React from "react";

function StaticFileDelivery({profile}) {
    const classes = useStyles();
    const [templates] = useTemplates();
    const {enqueueSnackbar} = useSnackbar();

    function buildLink(url) {
        return <a href={url} target="_blank">link</a>;
    }

    function deliverOnce(url) {
        const logRecord = deliveryLogTable.doc();
        const data = {
            status: 'pending',
            address: url,
            profile: profile.user.uid,
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
        };
        return logRecord.set(data).then(() => enqueueSnackbar('Delivery started, check your rM in a minute',
            {
                variant: 'info',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                TransitionComponent: Slide,
            }));
    }

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="h2">
                    Static file delivery
                </Typography>
                <Typography className={classes.pos} color="textSecondary" component="div">
                    Choose a file and deliver it instantly
                </Typography>
                <Grid container spacing={3} m={2}>
                    {templates.map((template) =>
                        <Grid key={template} item xs={12} sm={4}>
                            <Card className={classes.root}>
                                <CardHeader
                                    avatar={
                                        <Avatar aria-label="recipe">
                                            <a href={template.url} target="_blank">
                                                <PictureAsPdfIcon/>
                                            </a>
                                        </Avatar>
                                    }
                                    title={template.visibleName}
                                    subheader={buildLink(template.url)}
                                />

                                <CardContent>
                                    {/*<Typography variant="body2" color="textSecondary" component="p">*/}
                                    {/*    You can deliver up to 3 pages*/}
                                    {/*</Typography>*/}
                                </CardContent>
                                <CardActions>
                                    <Button size="medium" onClick={() => deliverOnce(template.url)}>deliver</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    )
}

export default withSnackbar(StaticFileDelivery)