import {storage} from "../firebase.utils";
import {useEffect, useState} from "react";

function useTemplates() {
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        async function fetchMyAPI() {
            storage.ref().child('templates').listAll()
                .then((res) => {
                    const r = res.items.map((itemRef) => {
                        return Promise.all([itemRef.getDownloadURL(), itemRef.getMetadata()]).then((values) => {
                            if (!!values[0] && !!values[1].customMetadata && !!values[1].customMetadata.visibleName) {
                                return {
                                    url: values[0],
                                    visibleName: values[1].customMetadata.visibleName
                                };
                            } else {
                                return null;
                            }
                        });
                    });
                    Promise.all(r).then((values) => {
                        setTemplates(values.filter(e => e !== null));
                    });
                }).catch((error) => {
                console.error(error);
            });
        }

        fetchMyAPI();
    }, []);

    return [templates];
}

export default useTemplates;