import './NotConnected.css'
import {authDevice} from "../../firebase.utils";
import useInput from "../../hooks/useInput";
import {useState} from "react";
import {Box, Button, Card, CardActions, CardContent, CircularProgress} from "@material-ui/core";
import useStyles from "../../styles";
import Typography from "@material-ui/core/Typography";


export default function NotConnected({profile}) {
    const classes = useStyles();

    const [isConnecting, setIsConnecting] = useState(false);
    const [otc, otcInput] = useInput({
        id: "onetime-code",
        type: "text",
        placeholder: 'One-time code',
        enabled: !isConnecting
    });

    return (
        <Box m={2}>
            <Card>
                <CardContent>
                    <Typography variant="h5" component="h2">
                        Not connected
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        To connect your device go to <a
                        target="_blank"
                        href="https://my.remarkable.com/connect/desktop">my.remarkable.com</a> to get a One-time code.
                        Then paste the code into the form
                    </Typography>
                    {!isConnecting && <div className="code-block">
                        {otcInput}
                    </div>}
                    {isConnecting && <Typography className="code-block">
                        <CircularProgress/>
                        Connecting...
                    </Typography>}
                </CardContent>
                <CardActions>
                    <Button size="medium" onClick={connect} disabled={isConnecting}>connect</Button>
                </CardActions>
            </Card>
        </Box>
    )

    function connect() {
        if (!!otc === false || otc.length !== 8) {
            console.error("OTC is invalid");
            return;
        }
        setIsConnecting(true);
        authDevice({otc: otc})
            .then(resp => {
                console.log("Got response ", resp.data.status);
                setIsConnecting(false);
            })
            .catch((error) => {
                console.error(error);
                setIsConnecting(false);
            });
    }
}