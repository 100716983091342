import {
    Avatar,
    Box,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {DeleteForeverOutlined} from "@material-ui/icons";

export default function UnauthPanel() {

    return (
        <Box width={1} m={2}>
            <Card>

                <CardContent>
                    <Typography variant="h4" component="h2">
                        Hello, I'm reMarkable Ninja
                    </Typography>
                    <Typography>
                        I can deliver content to your reMarkable2 tablet, that is pretty easy
                    </Typography>

                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    1
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Login" secondary="via google (see top right corner)" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    2
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Connect" secondary="your rM tablet" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    3
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Add" secondary="web addresses" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    4
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Read" secondary="I will deliver the web page snapshot to your rM tablet every morning" />
                        </ListItem>
                    </List>

                    <Typography>
                        Read more <a href="https://remarkable.ninja/landing.html">here</a>
                    </Typography>
                    <img src="/img/rMn.png"></img>
                </CardContent>
            </Card>
        </Box>
    )
}