import './AdminPanel.css'
import {Box, Chip} from "@material-ui/core";
import {useCollectionDataOnce} from "react-firebase-hooks/firestore";
import firebase from "firebase";
import {useEffect, useState} from "react";
import StaticTimePicker from "../StaticTimePicker";

export default function AdminPanel({profile}) {
    const [values, loading, error] = useCollectionDataOnce(firebase.firestore().collection('Profile'), {});
    const [deliveriesCount, setDeliveriesCount] = useState(0);
    useEffect(() => {
        if (!!values) {
            const newValue = values
                .map(p => {
                    return (!!p && !!p.deliveries) ? p.deliveries.length : 0
                })
                .reduce(function (result, item) {
                    return result + item;
                }, 0);
            setDeliveriesCount(newValue);
        } else {
            setDeliveriesCount(0);
        }
    }, values);

    return (
        <div>
            {!!values && profile.user.uid === "QFU52bV3kFRM3YIwOugnCrC8B012" && <Box width={1}>
                <Chip label={"Profiles: " + values.length} variant="outlined"/>
                <Chip label={"Deliveries: " + deliveriesCount} variant="outlined"/>
            </Box>}
        </div>
    )
}