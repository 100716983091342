import {useState} from "react";
import {TextField} from "@material-ui/core";

function useInput({type, placeholder, id, enabled}) {
    const [value, setValue] = useState("");
    const input = <TextField
        value={value}
        onChange={e => setValue(e.target.value)}
        type={type}
        placeholder={placeholder}
        name={id}
        enabled={enabled}
        id={id}/>
    return [value, input, () => setValue("")];
}

export default useInput;