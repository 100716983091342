import React, {useEffect} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Grid
} from "@material-ui/core";
import {signInWithGoogle, signOut} from './firebase.utils.js';
import MainPanel from "./components/MainPanel/MainPanel";
import useProfile from "./hooks/useProfile";
import useStyles from "./styles";
import StickyFooter from "./components/StickyFooter/StickyFooter";
import UnauthPanel from "./components/UnauthPanel/UnauthPanel";
import NotConnected from "./components/NotConnected/NotConnected";

const STATUSES = {
    LOADING: "loading",
    NOT_AUTHED: "not-authed",
    NOT_CONNECTED: "not-connected",
    CONNECTED: "connected",
    ERROR: "error"
};
export default function MenuAppBar() {
    const classes = useStyles();
    const [profile, loading1, error1] = useProfile();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [user, setUser] = React.useState(null);
    const [status, setStatus] = React.useState(STATUSES.LOADING);
    const open = Boolean(anchorEl);

    useEffect(() => {
        let newStatus;
        if (!!error1) {
            newStatus = STATUSES.ERROR;
        } else if (!!loading1) {
            newStatus = STATUSES.LOADING;
        } else if (!!profile === false) {
            newStatus = STATUSES.NOT_AUTHED;
        } else if (!!profile.data.isConnected === false) {
            newStatus = STATUSES.NOT_CONNECTED;
        } else {
            newStatus = STATUSES.CONNECTED;
        }
        //console.log(loading1, error1, profile, newStatus);
        if (!!profile && !!profile.user) {
            setUser(profile.user);
        }
        setStatus(newStatus);
    }, [profile, loading1, error1]);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        reMarkable.ninja BETA
                    </Typography>
                    {!!user && (
                        <div>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit">
                                <Avatar alt={user.name} src={user.photoURL}/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose}><Typography>{user.email}</Typography></MenuItem>
                                <MenuItem onClick={signOut}><Typography>Logout</Typography></MenuItem>
                            </Menu>
                        </div>
                    )}

                    {status === STATUSES.NOT_AUTHED && (
                        <div>
                            <Button
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={signInWithGoogle}
                                color="inherit">
                                Login via Google
                            </Button>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
            <Box m={2}>
                {status === STATUSES.LOADING &&
                <Grid container
                      spacing={3}
                      m={2}
                      justify="center"
                      direction="row"
                      alignItems="center">
                    <Grid item xs={12}>
                        <Box m={10}>
                            <Typography align="center" className="code-block">
                                <CircularProgress/>
                                Loading...
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                }

                {status === STATUSES.ERROR && <div>Sorry... something's wrong, please try again later...</div>}
                {status === STATUSES.NOT_AUTHED && <UnauthPanel/>}
                {status === STATUSES.NOT_CONNECTED && <NotConnected profile={profile}/>}
                {status === STATUSES.CONNECTED && <MainPanel profile={profile}/>}
            </Box>
            <StickyFooter/>
        </div>
    );
}