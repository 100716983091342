import React from "react";
import {
    Card,
    CardContent,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import useStyles from "../styles";
import useDeliverTime from "../hooks/useDeliverTime";
import SettingsSelect from "./SettingsSelect";

export default function StaticTimePicker({profile}) {
    const classes = useStyles();
    const [deliveryHour, timeZone] = useDeliverTime(profile);
    const timePoints = [6, 8, 10, 12, 14, 16, 18, 20, 22];

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="h2">
                    Settings
                </Typography>
                <Typography className={classes.pos} color="textSecondary" component="div">
                    Choose a delivery time (timezone: {timeZone})
                </Typography>
                <SettingsSelect
                    values={timePoints}
                    selectedValue={deliveryHour}
                    label="Delivery time"
                    updateFn={(newV) => {
                        return profile.docRef.update({
                            settings: {
                                deliveryTime: newV,
                                timeZone: timeZone
                            }
                        })
                    }}/>
            </CardContent>
        </Card>
    );
};
