import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary">
            <Link color="inherit" href="https://remarkable.ninja/landing.html">
                reMarkable Ninja
            </Link>{' '}
            {new Date().getFullYear()}
            {' '} | email info@reMarkable.ninja
        </Typography>
    );
}

export default function StickyFooter() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <CssBaseline/>
            {/*<Container component="main" sx={{mt: 8, mb: 2}} maxWidth="sm">*/}
            {/*    <Typography variant="body1">Sticky footer placeholder.</Typography>*/}
            {/*</Container>*/}
            <Box
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto',
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[200]
                            : theme.palette.grey[800],
                }}
            >
                <Container maxWidth="sm">
                    <Copyright/>
                </Container>
            </Box>
        </Box>
    );
}