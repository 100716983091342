import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import React from "react";
import useStyles from "../styles";
import useSnackbarWrapper from "../hooks/useSnackbarWrapper";

export default function SettingsSelect({values, selectedValue, updateFn, label}) {
    const classes = useStyles();

    const [snack] = useSnackbarWrapper(updateFn);

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="delivery-time-outlined-label">{label}</InputLabel>
            <Select
                labelId="delivery-time-outlined-label"
                id="delivery-time-select"
                value={selectedValue}
                onChange={(event) => {
                    snack(event.target.value);
                }}
                label={label}
            >
                {values.map((p) =>
                    <MenuItem key={p} value={p} selected={selectedValue === p}>{p}</MenuItem>
                )}
            </Select>
        </FormControl>
    )
}