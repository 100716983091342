import './Deliveries.css'
import firebase from "firebase";
import {
    Avatar, Button,
    Card, CardActions,
    CardContent, CardHeader, Grid,
} from "@material-ui/core";
import useStyles from "../../styles";
import Typography from "@material-ui/core/Typography";
import HttpOutlinedIcon from '@material-ui/icons/HttpOutlined';
import NewDelivery from "../NewDelivery/NewDelivery";
import React from "react";
import SettingsSelect from "../SettingsSelect";

export default function Deliveries({profile}) {
    const classes = useStyles();
    return (
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Deliveries
                </Typography>
                <Typography className={classes.pos} color="textSecondary" component="div">

                </Typography>

                <Grid container spacing={3} m={2}>
                    {profile.data.deliveries.map(item =>
                        <Grid key={item.address} item xs={12} sm={4}>
                            <Card className={classes.root}>
                                <CardHeader
                                    avatar={
                                        <Avatar aria-label="recipe">
                                            <a href={item.address} target="_blank">
                                                <HttpOutlinedIcon/>
                                            </a>
                                        </Avatar>
                                    }
                                    title={new URL(item.address).host}
                                    subheader={new URL(item.address).pathname}
                                />

                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        You can deliver up to 3 pages
                                    </Typography>
                                    <SettingsSelect
                                        values={['1-1', '1-2', '1-3']}
                                        selectedValue={item.pageRanges || '1-3'}
                                        label="Page range"
                                        updateFn={(newV) => {
                                            const newItem = {...item, pageRanges: newV};
                                            const p2 = profile.docRef.update({
                                                deliveries: firebase.firestore.FieldValue.arrayRemove(item)
                                            });
                                            const p1 = profile.docRef.update({
                                                deliveries: firebase.firestore.FieldValue.arrayUnion(newItem)
                                            });
                                            return Promise.all([p1, p2]);
                                        }}/>
                                </CardContent>
                                <CardActions>
                                    <Button size="medium" onClick={() => deleteDelivery(item)}>delete</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={4}>
                        <NewDelivery profile={profile}/>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )

    function deleteDelivery(item) {
        profile.docRef.update({
            deliveries: firebase.firestore.FieldValue.arrayRemove(item)
        });
    }
}