import './Connected.css'
import {Box, Button, Card, CardActions, CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../styles";


export default function Connected({profile}) {
    const classes = useStyles();
    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="h2">
                    Connected
                </Typography>
                <Typography className={classes.pos} color="textSecondary" component="div">
                    You can disconnect your device
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="medium" onClick={disconnect}>disconnect</Button>
            </CardActions>
        </Card>
    )

    function disconnect() {
        profile.docRef.set({isConnected: false, token: null, isEnabled: true, maxDeliveries: 2}, {merge: true});
    }
}