import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import {createStore} from 'redux';
import {Provider} from 'react-redux';
import {allReducers} from './redux/reducers';

import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {SnackbarProvider} from "notistack";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const store = createStore(
    allReducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

Sentry.init({
    dsn: "https://148a0bef09bb4f9bb1bcbef150c9926c@o250801.ingest.sentry.io/5686234",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
});

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <SnackbarProvider maxSnack={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <App/>
                </MuiPickersUtilsProvider>
            </SnackbarProvider>
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);