import './NewDelivery.css'
import useInput from "../../hooks/useInput";
import firebase from "firebase";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../styles";
import {withSnackbar} from "notistack";

function NewDelivery({profile}) {
    const classes = useStyles();
    const maxReached = profile.data.maxDeliveries - profile.data.deliveries.length <= 0;
    const [address, addressInput, flushAddress] = useInput({
        id: "delivery-name1",
        type: "text",
        placeholder: 'Web address'
    });

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="h5">
                    Add new web page delivery
                </Typography>
                {!maxReached && <div>
                    <Typography className={classes.pos} color="textSecondary" component="div">
                        start with "https://"
                    </Typography>

                    {addressInput}
                </div>
                }
                {maxReached && <Typography>
                    You can have {profile.data.maxDeliveries} deliveries only
                </Typography>}
            </CardContent>
            {!maxReached && <CardActions>
                <Button size="medium" onClick={save}>add</Button>
            </CardActions>}
        </Card>
    )

    function save() {
        if (!isValidAddress(address)) {
            return;
        }
        const newDelivery = {
            address: address,
            type: "URL",
            isEnabled: true
        };
        flushAddress();
        profile.docRef.update({
            deliveries: firebase.firestore.FieldValue.arrayUnion(newDelivery)
        });
    }

    function isValidAddress(address) {
        const result = /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(address);
        if (!result) {
            console.error("Address is invalid: " + address);
        }
        return result;
    }
}

export default withSnackbar(NewDelivery)