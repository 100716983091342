import {useState, useEffect} from 'react';
import {useAuthState} from "react-firebase-hooks/auth";
import firebase from "../firebase.utils";

const defaultProfile = {
    isEnabled: true,
    isConnected: false,
    deliveries: [],
    maxDeliveries: 2,
    settings: {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        deliveryTime: 6
    }
};

function useProfile() {
    const [user, loading, error] = useAuthState(firebase.auth());
    const [profile, setProfile] = useState(null);
    const [loading1, setLoading1] = useState(true);

    useEffect(() => {
        async function fetchMyAPI() {
            if (user) {
                const docUrl = "Profile/" + user.uid;
                const docRef = firebase.firestore().doc(docUrl);
                docRef.onSnapshot((doc) => {
                    const result = {
                        docRef: docRef
                    };
                    if (doc.exists) {
                        result.data = doc.data();
                        result.data.deliveries.sort((a, b) => a.address.localeCompare(b.address));
                        result.user = user;
                    } else {
                        console.log("No such document, creating one");
                        docRef.set(defaultProfile).then((createdProfile) => {
                            console.log(createdProfile);
                        });
                        result.data = defaultProfile;
                    }
                    setProfile(result);
                    setLoading1(false);
                });
            } else {
                setProfile(null);
                setLoading1(false);
            }
        }

        fetchMyAPI();
    }, [user]);

    return [profile, loading || loading1, error];
}

export default useProfile;