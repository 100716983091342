import {useSnackbar} from "notistack";
import {Slide} from "@material-ui/core";

function useSnackbarWrapper(fn) {
    const {enqueueSnackbar} = useSnackbar();

    const wrap = value =>
        fn(value)
            .then(() => {
                enqueueSnackbar('Saved',
                    {
                        variant: 'success',
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        TransitionComponent: Slide,
                    });
            })
            .catch(() => {
                enqueueSnackbar('Error saving',
                    {
                        variant: 'error',
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        TransitionComponent: Slide,
                    });
            });

    return [wrap];
}

export default useSnackbarWrapper;